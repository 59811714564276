body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
.site-layout-background {
  min-height: calc(100vh - 64px);
}
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  cursor: pointer;
  -webkit-box-shadow:inset 10px 10px 10px 10px #c9c9c9;
  box-shadow: inset 10px 10px 10px 10px #c9c9c9;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow:inset 10px 10px 10px 10px #001529;
  box-shadow: inset 10px 10px 10px 10px #001529;
  cursor: pointer;

}
.search-bar {
  width: 300px;
  padding: 16px 5px;
  .ant-input-wrapper {
    input {
      background-color: #001529 !important;
      color: white;
      border: 1px solid white;
    }
  }
  .ant-input-group-addon {
    button {
      background-color: #001529 !important;
      span {
        color: white;
      }
    }
  }
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.sel-dropdown{
  z-index: 99999999999999999999999999 !important; 
}

.fade-in {
  visibility: visible;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

 

  @media (min-width: 767px) {
    .ql-editor {
      p {
        > img{
          margin: 30px 0px;
          padding: 0px 50px;
        }
      }
    }
    }


//screens
@import "./screens/getAccess.scss";

//components
@import "./components/ads.scss";
@import "./components/layout.scss";
@import "./components/button.scss";
@import "./components/logo.scss";
@import "./components/footer.scss";
@import "./components/cfis.scss";
@import "./components/modal.scss";
@import "./components/spinner.scss";
@import "./components/pageHeader.scss";
@import "./components/static.scss";
@import "./components/thankyou.scss";
@import "./components/rev-acc.scss";
@import "./components/blog.scss";
